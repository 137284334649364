<template>
  <v-tooltip
    right
    open-delay="400"
    v-bind="$attrs"
    v-on="$listeners"
    :color="color"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon style="cursor: help" v-bind="attrs" v-on="on">$help</v-icon>
    </template>
    <slot></slot>
  </v-tooltip>
</template>

<script>
export default {
  name: "HelpTooltip",
  props: {
    action: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return this.action ? "#236496" : "#14415f";
    },
  },
};
</script>
